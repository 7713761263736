//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isStickyButtonVisible: false,
    }
  },

  computed: {
    targetedElements() {
      return document.querySelectorAll('.hide-sticky-button')
    },
  },

  mounted() {
    window.addEventListener('scroll', this.hasTargetedElementsInViewport)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.hasTargetedElementsInViewport)
  },

  methods: {
    hasTargetedElementsInViewport() {
      const isElementsInViewport = []

      /*
       * for each elements in the targetedElements NodeList
       * we are checking if an element is in the viewport.
       *
       * If an element is present this component should be hided.
       */

      this.targetedElements.forEach(ref => {
        const rect = ref.getBoundingClientRect()

        isElementsInViewport.push(
          rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth)
        )
      })

      this.isStickyButtonVisible = !isElementsInViewport.includes(true)
    },
  },
}
