//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NxImg from './NxImg.vue'

export default {
  components: { NxImg },

  props: {
    points: { type: Array, required: true },
    illustration: { type: Object, default: undefined },
    displayIllustration: { type: Boolean, default: true },
  },
}
