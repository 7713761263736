export default {
  methods: {
    getlocalizedPrice(price, currencyCode) {
      return new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
        style: 'currency',
        currency: currencyCode,
      }).format(price)
    },

    getFirstPaymentDateFromNow(daysNumber) {
      let date = new Date(Date.now())
      date.setDate(date.getDate() + daysNumber)
      return new Intl.DateTimeFormat(this.$i18n.localeProperties.iso, {
        month: 'long',
        day: 'numeric',
      }).format(date)
    },

    getLocalizedDate(date, locale) {
      return new Intl.DateTimeFormat(locale).format(Date.parse(date))
    },
  },
}
