var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nx-bg-picture',{staticClass:"hero bg--gray-800",attrs:{"mobile-img":{
    src: _vm.$hummingbird.marketProperties.img.homepage.hero_mobile,
    width: 375,
    height: 600,
  },"tablet-img":{
    src: _vm.$hummingbird.marketProperties.img.homepage.hero_tablet,
    width: 768,
    height: 800,
  },"desktop-img":{
    src: _vm.$hummingbird.marketProperties.img.homepage.hero_desktop,
    width: 1440,
    height: 900,
  },"alt":"background-image"}},[_c('div',{staticClass:"spacer--big"}),_vm._v(" "),_c('div',{staticClass:"container px-24"},[_c('div',{staticClass:"pt-32 pb-0 pb-md-64 pt-sm-64"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-xl-4"},[_c('p',{staticClass:"headline--big-xs text--gray-200 mb-8 mb-sm-24"},[_vm._v("\n            "+_vm._s(_vm.$t('homepage.hero.title'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"text--gray-200 mb-32"},[_vm._v("\n            "+_vm._s(_vm.$t('homepage.hero.body', {
                price: _vm.getLowestPrice,
              }))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-xl-8"},[_c('a',{staticClass:"hide-sticky-button button button--primary w-100 mb-16",attrs:{"href":_vm.$link.profile('/login'),"data-test":"btn-hero-register"}},[_vm._v("\n                "+_vm._s(_vm.$t('global.button_try_free', {
                    trialDays: _vm.$hummingbird.marketProperties.trialDays,
                  }))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"text--meta text-center text--gray-200"},[_vm._v("\n                "+_vm._s(_vm.$t('global.microcopy_cancel_anytime'))+"\n              ")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }