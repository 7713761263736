//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NxImg from './NxImg.vue'
export default {
  components: { NxImg },
  props: {
    title: { type: String, default: '' },
    details: { type: String, default: '' },
    src: { type: String, default: '' },
    width: { type: String, default: '128' },
    height: { type: String, default: '128' },
    alt: { type: String, default: 'image' },
    imgLeft: { type: Boolean, default: false },
  },
}
