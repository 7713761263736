//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  layout: 'transparentHeader',

  data() {
    return {
      illustratedDetailsSection: {
        firstElement: {
          title: this.$t('homepage.more_about.title.usp_read_or_listen'),
          details: this.$t('homepage.more_about.body.usp_read_or_listen'),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_1,
          alt: this.$t('homepage.more_about.img_1.alt'),
        },
        secondElement: {
          title: this.$t('homepage.more_about.title.usp_books_for_family'),
          details: this.$t('homepage.more_about.body.usp_books_for_family'),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_2,
          alt: this.$t('homepage.more_about.img_2.alt'),
          imgLeft: true,
        },
        thirdElement: {
          title: this.$t('homepage.more_about.title.usp_life_better_w_books'),
          details: this.$t('homepage.more_about.body.usp_life_better_w_books'),
          src: this.$hummingbird.marketProperties.img.homepage.more_about_3,
          alt: this.$t('homepage.more_about.img_3.alt'),
        },
      },

      sellingPoints: [
        {
          img: 'nx-images/home/usp/global/usp-logo-first.png',
          alt_img: 'wreath-owl',
          title: this.$t('homepage.usp1.title'),
          text: this.$t('homepage.usp1.body'),
          width: '130',
          height: '60',
        },
        {
          img: 'nx-images/home/usp/global/usp-logo-second.png',
          alt_img: 'wreath-stars',
          title: this.$t('homepage.usp2.title'),
          text: this.$t('homepage.usp2.body'),
          width: '130',
          height: '60',
        },
        {
          img: 'nx-images/home/usp/global/usp-logo-third.png',
          alt_img: 'wreath-challenge',
          title: this.$t('homepage.usp3.title'),
          text: this.$t('homepage.usp3.body'),
          width: '130',
          height: '60',
        },
      ],

      userReviewSection: {
        reviewText: this.$t('homepage.testimonial.quote'),
        userName: this.$t('homepage.testimonial.name'),
      },
    }
  },

  head() {
    return {
      title: this.$t('meta.home.title', {
        trialDays: this.$hummingbird.marketProperties.trialDays,
      }),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.home.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
        },
        { hid: 'ogUrl', property: 'og:url', content: this.$route.fullPath },
        {
          hid: 'ogTitle',
          property: 'og:title',
          content: this.$t('meta.home.title', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
        },
        {
          hid: 'ogDescription',
          property: 'og:description',
          content: this.$t('meta.home.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
        },
        {
          hid: 'twitterDescription',
          name: 'twitter:description',
          content: this.$t('meta.home.description', {
            trialDays: this.$hummingbird.marketProperties.trialDays,
          }),
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['getOpportunities']),
  },

  beforeMount() {
    this.getAvailableOpportunities()
  },

  methods: {
    ...mapActions(['getAvailableOpportunities', 'getOrderedOpportunities']),
  },
}
