import { render, staticRenderFns } from "./index.vue?vue&type=template&id=45b21d18&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadingHome: require('/app/apps/nx-web-www/components/HeadingHome.vue').default,IllustratedDetails: require('/app/packages/nx-components/src/IllustratedDetails.vue').default,SellingPoints: require('/app/packages/nx-components/src/SellingPoints.vue').default,UserReview: require('/app/packages/nx-components/src/UserReview.vue').default,StickyButton: require('/app/apps/nx-web-www/components/stickyButton.vue').default})
