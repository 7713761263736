//
//
//
//
//
//

import ImgixClient from '@imgix/js-core'

export default {
  props: {
    lowHeight: { type: String, default: '28' },
    lowWidth: { type: String, default: '32' },
    mobileImg: {
      type: Object,
      default() {
        return { src: undefined, width: 375, height: 600 }
      },
    },
    tabletImg: {
      type: Object,
      default() {
        return { src: undefined, width: 768, height: 800 }
      },
    },
    desktopImg: {
      type: Object,
      default() {
        return { src: undefined, width: 1440, height: 900 }
      },
    },
    alt: { type: String, required: true },
  },

  data() {
    return {
      isLoaded: false,
      ixClient: new ImgixClient({
        domain: 'nextory-asserts.imgix.net',
      }),

      ixLowOptions: {
        w: this.lowWidth,
        h: this.lowHeight,
        auto: 'format',
      },

      ixMobileOptions: {
        w: this.mobileImg.width,
        h: this.mobileImg.height,
        auto: 'format',
      },

      ixTabletOptions: {
        w: this.tabletImg.width,
        h: this.tabletImg.height,
        auto: 'format',
      },

      ixDesktopOptions: {
        w: this.desktopImg.width,
        h: this.desktopImg.height,
        auto: 'format',
      },
    }
  },

  computed: {
    ixLowSrc() {
      return this.ixClient.buildURL(this.mobileImg.src, this.ixLowOptions)
    },

    ixMobileSrc() {
      return this.ixClient.buildURL(this.mobileImg.src, this.ixMobileOptions)
    },

    ixTabletSrc() {
      return this.ixClient.buildURL(this.tabletImg.src, this.ixTabletOptions)
    },

    ixDesktopSrc() {
      return this.ixClient.buildURL(this.desktopImg.src, this.ixDesktopOptions)
    },
  },

  beforeDestroy() {
    if (this.isLoaded) {
      window.removeEventListener('resize', this.onResize)
    }
  },

  mounted() {
    const hero = this.$refs.hero
    hero.style.backgroundImage = `url(${this.ixLowSrc})`
    const img = new Image()
    img.src = this.getImgFromScreenWidth()
    img.onload = () => {
      this.isLoaded = true
      window.addEventListener('resize', this.onResize)
      this.onResize()
    }
  },

  methods: {
    getImgFromScreenWidth() {
      if (window.innerWidth >= 1440) {
        return this.ixDesktopSrc
      } else if (window.innerWidth >= 768) {
        return this.ixTabletSrc
      } else {
        return this.ixMobileSrc
      }
    },

    onResize() {
      const hero = this.$refs.hero
      hero.style.backgroundImage = `url(${this.getImgFromScreenWidth()})`
    },
  },
}
