//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NxImg from './NxImg.vue'

export default {
  props: {
    src: { type: String, required: true },
    alt: { type: String, required: true },
    width: { type: String, default: '80' },
    height: { type: String, default: '80' },
    review: { type: String, required: true },
    userName: { type: String, required: true },
  },
}
