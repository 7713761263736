import { render, staticRenderFns } from "./SellingPoints.vue?vue&type=template&id=55822302&"
import script from "./SellingPoints.vue?vue&type=script&lang=js&"
export * from "./SellingPoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NxImg: require('/app/packages/nx-components/src/NxImg.vue').default})
