//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import localized from '@nextory/components/mixins/localized'

export default {
  mixins: [localized],

  computed: {
    getLowestPrice() {
      // hard-coded for legacy (FR/BE markets)
      return this.getlocalizedPrice(9.99, 'EUR')
    },
  },
}
